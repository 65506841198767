body {
  margin: 0;
  background: #30343F;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

.content {
  margin: 160px 70px 0 70px;
  font-family: Raleway, sans-serif;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
