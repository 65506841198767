.videos {
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    margin: 64px 64px;
    padding: 20px 20px;
    background: rgba(223, 248, 235, 0.3);
    border-radius: 50px;
    overflow-y: auto;
    height: calc(100vh - 178px);
}

.videos a {
    margin-left: 15px;
}


@media (max-width: 1024px) {
    .videos {
        justify-content: space-around;
    }
    .videos a {
        margin-left: 0;
    }
}