.menu {
    position: absolute;
    left: 0;
    bottom: 64px;
    width: 25%;
    max-width: 125px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    color: #DFF8EB;
    border-radius: 0 50px 50px 0;
}

.menu:hover {
    background: #DFF8EB;
    color: #30343F !important;
}

.main {
    display: flex;
    align-items: center;
    font-size: 48px;
    z-index: 1;
}

@media (max-width: 768px) {
    .menu {
        width: 25%;
        max-width: 250px;
        bottom: 16px;
    }
}

@media (max-height: 450px) {
    .menu {
        display: none;
    }
}