.content-header {
    font-size: 48px;
    font-weight: 500;
    margin-bottom: 15px;
    text-align: center;
}

.content-player {
    max-width: 600px;
    height: fit-content;
    box-shadow: #DFF8EB 25px 25px;
    border-radius: 15px;
}

.content-player * {
    border-radius: 15px;
}

.content-tags {
    display: inline-flex;
    margin-bottom: 15px;
}


@media (max-width: 768px) {
    .content {
        margin: 160px 10px 0 10px;
    }
    .content-player {
        width: 100%;
        box-shadow: none;
        margin-top: 20px;
    }
}

@media (max-width: 500px) {
    .content-header {
        font-size: 32px;
    }
    .content {
        margin: 50vw 5px 0 5px;
    }
}

@media (max-height: 600px) {
    .content {
        margin: 0 10px 0 10px;
    }
}