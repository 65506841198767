.video {
    padding: 25px 10px;
    width: calc(100vw - 228px);
    max-width: 350px;
    height: 200px;
    background: rgba(223, 248, 235, 0.5);
    border-radius: 35px;
    font-size: 25px;
    margin-bottom: 25px;
}

a {
    color: #DFF8EB;
    text-decoration: none;
}

.video-thumbnail {
    width: 100%;
    height: 85%;
    margin-bottom: 10px;
    background-position: center;
    background-size: cover;
    border-radius: 35px;
}

.video-title {
    color: #30343F;
    font-family: Raleway, sans-serif;
    font-weight: 900;
    text-decoration-color: #FFFFFF;
    text-align: center;
}