.tag {
    display: flex;
    justify-content: center;
    align-items: baseline;
    padding: 5px 10px;
    border: #DFF8EB 1px solid;
    border-radius: 15px;
    font-family: Inter, sans-serif;
    font-size: 15px;
    margin-left: 15px;
    cursor: pointer;
    background: #FFF;
    color: #30343F;
    font-weight: bold;
    transition: all 0.2s ease-in-out;
}

.tag:hover {
    background: #30343F;
    color: #FFF;
}

.tag-icon {
    margin-right: 5px;
}