.literals {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: transparent;
    font-family: Gravitas One, cursive;
    font-size: 128px;
    color: #DFF8EB;
    z-index: -1;
}

.literals .upper-literal {
    position: absolute;
    top: -80px;
    left: 70px;
}

.literals .lower-literal {
    position: absolute;
    bottom: -80px;
    right: 70px;
}

@media (max-height: 600px) {
    .literals .upper-literal, .literals .lower-literal {
        display: none;
    }
}

@media (max-width: 500px) {
    .literals {
        font-size: 64px;
    }
}